import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MainNavContentComponent } from '../../../component/navigation/main-nav-content/main-nav-content.component';
import { SidenavContentComponent } from '../../../component/navigation/sidenav-content/sidenav-content.component';
import { ScrollEventService } from '../../../service/scroll-event/scroll-event.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  imports: [
    MatSidenavContainer,
    MatSidenav,
    NgClass,
    SidenavContentComponent,
    MatSidenavContent,
    MatToolbar,
    MainNavContentComponent,
    NgIf,
    MatIcon,
    AsyncPipe
  ]
})
export class MainNavComponent {
  @ViewChild('sidenavContent') sidenavContent!: MatSidenavContent;

  isHandset$: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private scrollEventService: ScrollEventService
  ) {
    this.isHandset$ = this.breakpointObserver.observe('(max-width: 1150px)').pipe(
      map((result) => result.matches),
      shareReplay()
    );
  }

  sendScrollEvent(elementRef: MatSidenavContent) {
    this.scrollEventService.emitScrollEvent(elementRef.getElementRef().nativeElement.scrollTop);
  }
}
