<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    [ngClass]="!(isHandset$ | async) ? 'hidden' : 'sidenav'"
    fixedInViewport="false"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    class="sidenav-content"
  >
    <app-sidenav-content (onCloseMenu)="drawer.toggle()"></app-sidenav-content>
  </mat-sidenav>
  <mat-sidenav-content #sidenavContent (scroll)="sendScrollEvent(sidenavContent)">
    <mat-toolbar color="primary">
      <app-main-nav-content class="nav-content"></app-main-nav-content>
      <button class="nav-button" type="button" (click)="drawer.toggle()" *ngIf="isHandset$ | async">
        <mat-icon class="navbar-menu-header-icon secondary-color" fontIcon="menu"></mat-icon>
      </button>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
