export const routesFrCa = [
  {
    path: 'fr-ca',
    children: [
      {
        path: '',
        loadComponent: () => import('../page/landings/home/home.component').then((m) => m.HomeComponent),
        data: { page: 'home' }
      },
      {
        path: 'solutions',
        children: [
          {
            path: 'smart-office',
            loadComponent: () => import('../page/landings/smart/smart.component').then((m) => m.SmartComponent),
            data: { page: 'smart' }
          },
          {
            path: 'logiciel-flex-office',
            loadComponent: () => import('../page/landings/flex/flex.component').then((m) => m.FlexComponent),
            data: { page: 'flex' }
          },
          {
            path: 'outil-suivi-travail-a-domicile',
            loadComponent: () => import('../page/landings/remote/remote.component').then((m) => m.RemoteComponent),
            data: { page: 'remote' }
          },
          {
            path: 'logiciel-gestion-places-de-stationnement',
            loadComponent: () => import('../page/landings/parking/parking.component').then((m) => m.ParkingComponent),
            data: { page: 'parking' }
          }
        ]
      },
      {
        path: 'integrations',
        children: [
          {
            path: 'desk-booking-microsoft-teams',
            loadComponent: () => import('../page/landings/waldo/waldo.component').then((m) => m.WaldoComponent),
            data: { page: 'waldo' }
          },
          {
            path: 'module-additionnel-google-workspace-bureaux-flexibles',
            loadComponent: () => import('../page/landings/google/google.component').then((m) => m.GoogleComponent),
            data: { page: 'google' }
          }
        ]
      },
      {
        path: 'partenaires',
        loadComponent: () =>
          import('../page/landings/marketplace/marketplace.component').then((m) => m.MarketplaceComponent),
        data: { page: 'marketplace' },
        children: [
          {
            path: ':id',
            loadComponent: () => import('../page/landings/partner/partner.component').then((m) => m.PartnerComponent),
            data: { page: 'marketplace' }
          }
        ]
      },
      {
        path: 'glossaire-flex-office',
        loadComponent: () => import('../page/landings/glossary/glossary.component').then((m) => m.GlossaryComponent),
        data: { page: 'glossary' },
        children: [
          {
            path: ':id',
            loadComponent: () =>
              import('../page/landings/definition/definition.component').then((m) => m.DefinitionComponent),
            data: { page: 'glossary' }
          }
        ]
      },
      {
        path: 'notre-offre-flex-office',
        loadComponent: () => import('../page/landings/offer/offer.component').then((m) => m.OfferComponent),
        data: { page: 'offer' }
      },
      {
        path: 'contact',
        loadComponent: () => import('../page/landings/contact/contact.component').then((m) => m.ContactComponent),
        data: { page: 'contact' }
      },
      {
        path: 'demo',
        loadComponent: () => import('../page/landings/contact/contact.component').then((m) => m.ContactComponent),
        data: { page: 'contact' }
      },
      {
        path: 'soumettre-une-idee',
        loadComponent: () =>
          import('../page/landings/contact-idea/contact-idea.component').then((m) => m.ContactIdeaComponent),
        data: { page: 'idea' }
      },
      {
        path: 'rejoindre-l-equipe',
        loadComponent: () =>
          import('../page/landings/contact-join-team/contact-join-team.component').then(
            (m) => m.ContactJoinTeamComponent
          ),
        data: { page: 'join_team' }
      },
      {
        path: 'message-envoye',
        loadComponent: () =>
          import('../page/landings/message-envoye/message-envoye.component').then((m) => m.MessageEnvoyeComponent),
        data: { page: 'message_sent' }
      }
    ]
  }
];
