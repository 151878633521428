import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './page/navigation/footer/footer.component';
import { MainNavComponent } from './page/navigation/main-nav/main-nav.component';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [MainNavComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const sessionJson = localStorage.getItem('session');

        if (sessionJson) {
          localStorage.clear();

          const session = JSON.parse(sessionJson);
          if (session) {
            this.http
              .patch(environment.apiUrl + '/auth/sessions', {
                refreshToken: session.refreshToken
              })
              .toPromise()
              .then((session: any) => {
                window.location.href =
                  'https://app.moffi.io?sid=' + session.id + '&token=' + session.accessToken + '&saveSession=true';
              });
          }
        }
      } catch (e) {
        // Nothing to do
      }
    }
  }
}
